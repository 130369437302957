import React from "react"
import Head from "../components/head"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <Head />
    <h1>Oh no, this page does not exist</h1>
    <p>
        <a href="/">Go to the homepage</a>
    </p>
  </Layout>
)

export default NotFoundPage
